import React from 'react';
import './datenschutz.css';
import '../media-queries/datenschutz-media-queries.css';
import BasicStructure from '../components/basic-structure/basic-structure';
import ContentContainer from '../components/content-container/content-container';
import Header from '../components/header/header';
import Menubar from '../components/menu/menubar';
import BurgerMenu from '../components/burgermenu/burgermenu';

export default function Datenschutz(): JSX.Element {
  return (
    <BasicStructure>
      <Header>
        <Menubar styleClass="main-menu" />
        <BurgerMenu />
      </Header>
      <ContentContainer>
        <div className="datenschutz__container">
          <p className="datenschutz__ueberschrift">Datenschutzerklärung</p>
          <p className="datenschutz__aufzaehlung">
            1. Datenschutz auf einen Blick
          </p>
          <p className="datenschutz__zwischenueberschrift">
            Allgemeine Hinweise
          </p>
          <p className="datenschutz__erklaerung">
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </p>
          <p className="datenschutz__zwischenueberschrift">
            Datenerfassung auf unserer Website
          </p>
          <p className="datenschutz__erklaerung">
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
            dieser Website entnehmen.
          </p>
          <p className="datenschutz__zwischenpunkt">
            Wie erfassen wir Ihre Daten?
          </p>
          <p className="datenschutz__erklaerung">
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben. Andere Daten werden automatisch beim
            Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
            allem technische Daten (z.B. Internetbrowser, Betriebssystem oder
            Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie unsere Website betreten.
          </p>
          <p className="datenschutz__zwischenpunkt">
            Wofür nutzen wir Ihre Daten?
          </p>
          <p className="datenschutz__erklaerung">
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </p>
          <p className="datenschutz__zwischenpunkt">
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </p>
          <p className="datenschutz__erklaerung">
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
            oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
            Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
            Impressum angegebenen Adresse an uns wenden. Des Weiteren steht
            Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
            Außerdem haben Sie das Recht, unter bestimmten Umständen die
            Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung
            unter „Recht auf Einschränkung der Verarbeitung“.
          </p>
          <p className="datenschutz__zwischenueberschrift">
            Analyse-Tools und Tools von Drittanbietern
          </p>
          <p className="datenschutz__erklaerung">
            Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit Cookies und mit
            sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
            erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
            zurückverfolgt werden. Sie können dieser Analyse widersprechen oder
            sie durch die Nichtbenutzung bestimmter Tools verhindern.
            Detaillierte Informationen dazu finden Sie in der folgenden
            Datenschutzerklärung. Sie können dieser Analyse widersprechen. Über
            die Widerspruchsmöglichkeiten werden wir Sie in dieser
            Datenschutzerklärung informieren.
          </p>

          <p className="datenschutz__aufzaehlung">
            2. Allgemeine Hinweise und Pflichtinformationen
          </p>

          <p className="datenschutz__zwischenueberschrift">Datenschutz</p>

          <p className="datenschutz__erklaerung">
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie
            diese Website benutzen, werden verschiedene personenbezogene Daten
            erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
            identifiziert werden können. Die vorliegende Datenschutzerklärung
            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
            erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen
            darauf hin, dass die Datenübertragung im Internet (z.B. bei der
            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.
          </p>
          <p className="datenschutz__zwischenueberschrift">
            Hinweis zur verantwortlichen Stelle
          </p>
          <p className="datenschutz__adresse">
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:<br></br>
            <br></br>Internationale Stiftung zur Förderung von Kultur und
            Zivilisation <br></br>Dr.-Carl-von-Linde-Str.9 <br></br>81479
            München
          </p>

          <a className="datenschutz__link" href="tel:+4908954041180">
            +49 (0)89 54 04 11 8 - 0
          </a>
          <div className="datenschutz__email">
            E-Mail:&nbsp;
            <a
              className="datenschutz__link"
              href="mailto: contact@kulturstiftungmuenchen.de"
            >
              contact@kulturstiftungmuenchen.de
            </a>
          </div>

          <p className="datenschutz__erklaerung">
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z.B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </p>
          <p className="datenschutz__zwischenueberschrift">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </p>

          <p className="datenschutz__erklaerung">
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
            E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>

          <p className="datenschutz__zwischenueberschrift">
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </p>

          <p className="datenschutz__erklaerung">
            Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e
            oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen,
            die sich aus Ihrer besonderen Situation ergeben, gegen die
            Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen;
            dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
            Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht,
            entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch
            einlegen, werden wir Ihre betroffenen personenbezogenen Daten nicht
            mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige
            Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
            und Freiheiten überwiegen oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            (Widerspruch nach Art. 21 Abs. 1 DSGVO). Werden Ihre
            personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben,
            so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung
            Sie betreffender personenbezogener Daten zum Zwecke derartiger
            Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit
            solcher Direktwerbung in Verbindung steht. Wenn Sie widersprechen,
            werden Ihre personenbezogenen Daten anschließend nicht mehr zum
            Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2
            DSGVO).
          </p>

          <p className="datenschutz__zwischenueberschrift">
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </p>

          <p className="datenschutz__erklaerung">
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </p>

          <p className="datenschutz__zwischenueberschrift">
            Recht auf Datenübertragbarkeit
          </p>

          <p className="datenschutz__erklaerung">
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </p>

          <p className="datenschutz__zwischenueberschrift">
            SSL- bzw. TLS-Verschlüsselung
          </p>

          <p className="datenschutz__erklaerung">
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
            SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
            erkennen Sie daran, dass die Adresszeile des Browsers von “http://”
            auf “https://” wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist,
            können die Daten, die Sie an uns übermitteln, nicht von Dritten
            mitgelesen werden.
          </p>

          <p className="datenschutz__zwischenueberschrift">
            Auskunft, Sperrung, Löschung und Berichtigung
          </p>

          <p className="datenschutz__erklaerung">
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu
            weiteren Fragen zum Thema personenbezogene Daten können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          </p>

          <p className="datenschutz__zwischenueberschrift">
            Recht auf Einschränkung der Verarbeitung
          </p>

          <p className="datenschutz__erklaerung">
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
            Das Recht auf Einschränkung der Verarbeitung besteht in folgenden
            Fällen: - Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit,
            um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das
            Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
            Daten zu verlangen. - Wenn die Verarbeitung Ihrer personenbezogenen
            Daten unrechtmäßig geschah / geschieht, können Sie statt der
            Löschung die Einschränkung der Datenverarbeitung verlangen. - Wenn
            wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
            jedoch zur Ausübung, Verteidigung oder Geltendmachung von
            Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
            die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen. - Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
            eingelegt haben, muss eine Abwägung zwischen Ihren und unseren
            Interessen vorgenommen werden. Solange noch nicht feststeht, wessen
            Interessen überwiegen, haben Sie das Recht, die Einschränkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Wenn Sie
            die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben,
            dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer
            Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen oder zum Schutz der Rechte einer anderen
            natürlichen oder juristischen Person oder aus Gründen eines
            wichtigen öffentlichen Interesses der Europäischen Union oder eines
            Mitgliedstaats verarbeitet werden.
          </p>

          <p className="datenschutz__aufzaehlung">
            3. Datenerfassung auf unserer Website
          </p>

          <p className="datenschutz__zwischenueberschrift">Cookies</p>

          <p className="datenschutz__erklaerung">
            Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
            richten auf Ihrem Rechner keinen Schaden an und enthalten keine
            Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
            effektiver und sicherer zu machen. Cookies sind kleine Textdateien,
            die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
            Die meisten der von uns verwendeten Cookies sind so genannte
            “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
            gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis
            Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
            beim nächsten Besuch wiederzuerkennen. Sie können Ihren Browser so
            einstellen, dass Sie über das Setzen von Cookies informiert werden
            und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für
            bestimmte Fälle oder generell ausschließen sowie das automatische
            Löschen der Cookies beim Schließen des Browser aktivieren. Bei der
            Deaktivierung von Cookies kann die Funktionalität dieser Website
            eingeschränkt sein. Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
            erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind,
            werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
            von Cookies zur technisch fehlerfreien und optimierten
            Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies
            zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese
            in dieser Datenschutzerklärung gesondert behandelt.
          </p>

          <p className="datenschutz__zwischenueberschrift">
            Server-Log-Dateien
          </p>

          <p className="datenschutz__erklaerung">
            Der Provider der Seiten erhebt und speichert automatisch
            Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind:
          </p>
          <u className="datenschutz__erklaerung">
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>IP-Adresse</li>
          </u>

          <p className="datenschutz__erklaerung">
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
            nicht vorgenommen. Die Erfassung dieser Daten erfolgt auf Grundlage
            von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
            berechtigtes Interesse an der technisch fehlerfreien Darstellung und
            der Optimierung seiner Website – hierzu müssen die Server-Log-Files
            erfasst werden.
          </p>

          <p className="datenschutz__aufzaehlung">
            4. Analyse-Tools und Werbung
          </p>

          <p className="datenschutz__zwischenueberschrift">Google Analytics</p>

          <p className="datenschutz__erklaerung">
            Diese Website nutzt Funktionen des Webanalysedienstes Google
            Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA. Google Analytics verwendet so genannte
            &quot;Cookies&quot;. Das sind Textdateien, die auf Ihrem Computer
            gespeichert werden und die eine Analyse der Benutzung der Website
            durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen
            über Ihre Benutzung dieser Website werden in der Regel an einen
            Server von Google in den USA übertragen und dort gespeichert. Die
            Speicherung von Google-Analytics-Cookies und die Nutzung dieses
            Analyse-Tools erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
            Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse
            des Nutzerverhaltens, um sowohl sein Webangebot als auch seine
            Werbung zu optimieren.
          </p>

          <p className="datenschutz__zwischenpunkt">IP Anonymisierung</p>

          <p className="datenschutz__erklaerung">
            Wir haben auf dieser Website die Funktion IP-Anonymisierung
            aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
            Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird
            die volle IP-Adresse an einen Server von Google in den USA
            übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
            Website wird Google diese Informationen benutzen, um Ihre Nutzung
            der Website auszuwerten, um Reports über die Websiteaktivitäten
            zusammenzustellen und um weitere mit der Websitenutzung und der
            Internetnutzung verbundene Dienstleistungen gegenüber dem
            Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics
            von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
            Daten von Google zusammengeführt.
          </p>

          <p className="datenschutz__zwischenpunkt">Browser Plugin</p>

          <p className="datenschutz__erklaerung">
            Sie können die Speicherung der Cookies durch eine entsprechende
            Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können. Sie
            können darüber hinaus die Erfassung der durch den Cookie erzeugten
            und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
            IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
            Google verhindern, indem Sie das unter dem folgenden Link verfügbare
            Browser-Plugin herunterladen und installieren:&nbsp;
            <a
              className="datenschutz__link"
              href="https://tools.google.com/dlpage/gaoptout?hl=de"
            >
              https://tools.google.com/dlpage/ gaoptout?hl=de.
            </a>
          </p>

          <p className="datenschutz__zwischenpunkt">
            Widerspruch gegen Datenerfassung
          </p>

          <p className="datenschutz__erklaerung">
            Sie können die Erfassung Ihrer Daten durch Google Analytics
            verhindern, indem Sie auf folgenden Link klicken. Es wird ein
            Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
            zukünftigen Besuchen dieser Website verhindert: Google Analytics
            deaktivieren. Mehr Informationen zum Umgang mit Nutzerdaten bei
            Google Analytics finden Sie in der Datenschutzerklärung von
            Google:&nbsp;
            <a
              className="datenschutz__link"
              href="https://support.google.com/analytics/answer/6004245?hl=de"
            >
              https://support.google.com/analytics/ answer/6004245?hl=de.
            </a>
          </p>

          <p className="datenschutz__zwischenpunkt">Auftragsverarbeitung</p>

          <p className="datenschutz__erklaerung">
            Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
            abgeschlossen und setzen die strengen Vorgaben der deutschen
            Datenschutzbehörden bei der Nutzung von Google Analytics vollständig
            um.
          </p>

          <p className="datenschutz__zwischenpunkt">
            Demografische Merkmale bei Google Analytics
          </p>

          <p className="datenschutz__erklaerung">
            Diese Website nutzt die Funktion “demografische Merkmale” von Google
            Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu
            Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese
            Daten stammen aus interessenbezogener Werbung von Google sowie aus
            Besucherdaten von Drittanbietern. Diese Daten können keiner
            bestimmten Person zugeordnet werden. Sie können diese Funktion
            jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto
            deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics
            wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell
            untersagen.
          </p>

          <p className="datenschutz__aufzaehlung">5. Plugins und Tools</p>

          <p className="datenschutz__zwischenueberschrift">
            YouTube mit erweitertem Datenschutz
          </p>

          <p className="datenschutz__erklaerung">
            Unsere Website nutzt Plugins der Website YouTube. Betreiber der
            Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066,
            USA. Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser
            Modus bewirkt laut YouTube, dass YouTube keine Informationen über
            die Besucher auf dieser Website speichert, bevor diese sich das
            Video ansehen. Die Weitergabe von Daten an YouTube-Partner wird
            durch den erweiterten Datenschutzmodus hingegen nicht zwingend
            ausgeschlossen. So stellt YouTube – unabhängig davon, ob Sie sich
            ein Video ansehen – eine Verbindung zum Google DoubleClick-Netzwerk
            her. Sobald Sie ein YouTube-Video auf unserer Website starten, wird
            eine Verbindung zu den Servern von YouTube hergestellt. Dabei wird
            dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht
            haben. Wenn Sie in Ihrem YouTube-Account eingeloggt sind,
            ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem persönlichen
            Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus
            Ihrem YouTube-Account ausloggen. Des Weiteren kann YouTube nach
            Starten eines Videos verschiedene Cookies auf Ihrem Endgerät
            speichern. Mit Hilfe dieser Cookies kann YouTube Informationen über
            Besucher unserer Website erhalten. Diese Informationen werden u. a.
            verwendet, um Videostatistiken zu erfassen, die
            Anwenderfreundlichkeit zu verbessern und Betrugsversuchen
            vorzubeugen. Die Cookies verbleiben auf Ihrem Endgerät, bis Sie sie
            löschen. Gegebenenfalls können nach dem Start eines YouTube-Videos
            weitere Datenverarbeitungsvorgänge ausgelöst werden, auf die wir
            keinen Einfluss haben. Die Nutzung von YouTube erfolgt im Interesse
            einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt
            ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO
            dar. Weitere Informationen über Datenschutz bei YouTube finden Sie
            in deren Datenschutzerklärung unter:&nbsp;
            <a
              className="datenschutz__link"
              href="http://www.youtube.com/t/privacy_at_youtube"
            >
              http://www.youtube.com/t/ privacy_at_youtube.
            </a>
          </p>
        </div>
      </ContentContainer>
    </BasicStructure>
  );
}
